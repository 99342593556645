.pricing-tier {
  cursor: pointer;
  text-align: left;
  padding: 0;
  margin-bottom: 1.875rem;
  border-radius: 0.25rem;
  width: 100%;
  height: 175px;
  overflow: hidden;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,.2);

  &.selected {
    cursor: default;
    border: 2px solid map-get($colors, primary-green);
    .title-container {
      margin: -2px -2px 0 -2px;
    }
    .options-container {
      margin: 0 -2px -2px -2px;
    }
  }

  .title-container {
    padding: 1.125rem 1rem 1rem 1rem;
    font-size: 1.125rem;
    font-weight: map-get($font-weights, semi-bold);
    color: #FFFFFF;

    &.B { background: map-get($tier-colors, bronze); } // bronze
    &.S { background: map-get($tier-colors, silver); } // silver
    &.G { background: map-get($tier-colors, gold); } // gold
    &.C { background: map-get($tier-colors, corporate); } // corporate
    &.Q { background: map-get($tier-colors, cqcd); } // CQCD
    &.A { background: map-get($tier-colors, panier); } // Panierdachat
    &.P { background: map-get($tier-colors, bluecross); } // Pacific Blue Cross
    &.F { background: map-get($tier-colors, affinity); } // affinity - Marketing campaigns
    &.E { background: map-get($tier-colors, employee); } // employee
  }
  .options-container {
    padding: 1.125rem 1rem 1rem 1rem;
    .margin-title {
      margin-bottom:  0.4rem;
    }
    .margin {
      font-size: 1.25rem;
      font-weight: map-get($font-weights, bold);
      color: map-get($colors, text-gray);
    }
  }
}
