.mat-drawer-content {
  min-height: 100vh;
  &.showMobileMenu {
    margin-left: 0 !important;
  }
}
.mtx-drawer-container {
  .mtx-drawer-content-wrapper {
    padding: 24px 16px 24px 24px;
  }
}

.mat-drawer.mat-sidenav {
  background: #E7F5F3;
  overflow: visible;
  .mat-mdc-list-item-title {
    span {
      display: inline-block;
      opacity: 1;
      transition: opacity 0.25s ease-in-out;
    }
  }
  &.sidenav-open {
    .mat-mdc-list-item-title {
      span {
        opacity: 1;
      }
    }
  }
  &.sidenav-closed {
    .mat-mdc-list-item-title {
      span {
        opacity: 0;
      }
    }
  }

  .mat-mdc-list-base {
    padding: 10px 15px;
  }

  .mat-drawer-inner-container {
    position: relative;
    overflow: visible;
  }
  .toggle-side-nav.mdc-fab {
    position: absolute;
    color: map-get($colors, primary-green);
    background: #FFFFFF;
    right: -21px;
    top: 50px;
    z-index: 10;
  }
  .mat-mdc-list-item {
    border-radius: 10px;
    &.with-spacer {
      margin-top: 100px;
    }
    &:before {
      background: map-get($colors, primary-green);
      transition: opacity 0.25s ease-in-out;
    }
    &.active {
      color: #FFFFFF;
      background: map-get($colors, primary-green);

      .mat-mdc-list-item-title span,
      .mat-icon {
        color: #FFFFFF;
      }
    }
    &:hover,
    &:active {
      &:before {
        opacity: 0.15;
      }
    }
    .mat-icon {
      font-size: 24px;
      height: 24px;
      width: 24px;
      margin-right: 5px;
      color: map-get($colors, icons);
    }
  }
}
