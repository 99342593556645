.mat-mdc-tab-group {
  &.tab-group-vertical {

    @media #{$medium-up} {
      flex-direction: row;
    }
    gap: calc($column-gutter * 1px);
    .mat-mdc-tab-labels {
      flex-direction: column;
      margin-top: 10px;
      .mat-mdc-tab.mdc-tab {
        min-width: 50px;
        padding-left:calc(($column-gutter / 2) * 1px);
        padding-right:calc(($column-gutter / 2) * 1px);
        justify-content: left;

        @media #{$medium-up} {
          min-width: 100px;
        }
      }
    }
    .mat-mdc-tab-body-wrapper {
      flex-grow: 1;
    }
    &.tab-no-animation {
      .mat-mdc-tab-body-wrapper {
        overflow: visible;
        .mat-mdc-tab-body-active {
          overflow: visible;
          width: 100%;
          .mat-mdc-tab-body-content {
            overflow: visible;
          }
        }
      }
    }
  }
  &.tab-group-horizontal {
    flex-direction: column;
    .mat-mdc-tab-labels {
      flex-direction: revert;
      margin-top: 0px;
    }
  }
}
