$directions: (
  't': top,
  'r': 'right',
  'b': 'bottom',
  'l': 'left',
);

.mt--auto {
  margin-top: auto !important;
}
.mb--auto {
  margin-bottom: auto !important;
}
.ml--auto {
  margin-left: auto !important;
}
.mr--auto {
  margin-right: auto !important;
}
@each $key, $value in $space-scale {
  .mb--none {
    margin-bottom: 0;
  }
  .mt--none {
    margin-top: 0;
  }
  .p--#{$key} {
    padding: spacing($key, $space-scale-mobile)!important;
  }
  .px--#{$key} {
    padding-left: spacing($key, $space-scale-mobile)!important;
    padding-right: spacing($key, $space-scale-mobile)!important;
  }
  .py--#{$key} {
    padding-top: spacing($key, $space-scale-mobile)!important;
    padding-bottom: spacing($key, $space-scale-mobile)!important;
  }
  .mx--#{$key} {
    margin-left: spacing($key, $space-scale-mobile)!important;
    margin-right: spacing($key, $space-scale-mobile)!important;
  }
  .my--#{$key} {
    margin-top: spacing($key, $space-scale-mobile)!important;
    margin-bottom: spacing($key, $space-scale-mobile)!important;
  }
  @media #{$medium-up} {
    .px--#{$key} {
      padding-left: spacing($key, $space-scale-tablet)!important;
      padding-right: spacing($key, $space-scale-tablet)!important;
    }
    .py--#{$key} {
      padding-top: spacing($key, $space-scale-tablet)!important;
      padding-bottom: spacing($key, $space-scale-tablet)!important;
    }
    .mx--#{$key} {
      margin-left: spacing($key, $space-scale-tablet)!important;
      margin-right: spacing($key, $space-scale-tablet)!important;
    }
    .my--#{$key} {
      margin-top: spacing($key, $space-scale-tablet)!important;
      margin-bottom: spacing($key, $space-scale-tablet)!important;
    }
  }
  @media #{$large-up} {
    .px--#{$key} {
      padding-left: spacing($key)!important;
      padding-right: spacing($key)!important;
    }
    .py--#{$key} {
      padding-top: spacing($key)!important;
      padding-bottom: spacing($key)!important;
    }
    .mx--#{$key} {
      margin-left: spacing($key)!important;
      margin-right: spacing($key)!important;
    }
    .my--#{$key} {
      margin-top: spacing($key)!important;
      margin-bottom: spacing($key)!important;
    }
  }
  @each $abb, $dir in $directions {
    .m#{$abb}--#{$key} {
      margin-#{$dir}: spacing($key, $space-scale-mobile)!important;
    }
    .p#{$abb}--#{$key} {
      padding-#{$dir}: spacing($key, $space-scale-mobile)!important;
    }
    @media #{$medium-up} {
      .m#{$abb}--#{$key} {
        margin-#{$dir}: spacing($key, $space-scale-tablet)!important;
      }
      .p#{$abb}--#{$key} {
        padding-#{$dir}: spacing($key, $space-scale-tablet)!important;
      }
    }
    @media #{$large-up} {
      .m#{$abb}--#{$key} {
        margin-#{$dir}: spacing($key)!important;
      }
      .p#{$abb}--#{$key} {
        padding-#{$dir}: spacing($key)!important;
      }
    }
  }
}
