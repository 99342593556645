.mat-mdc-chip {
  &.mat-mdc-standard-chip.chip-tier,
  &.chip-tier {
    height: 22px;
    &.tier-B { background: map-get($tier-colors, bronze); } // bronze
    &.tier-S { background: map-get($tier-colors, silver); } // silver
    &.tier-G { background: map-get($tier-colors, gold); }   // gold
    &.tier-C { background: map-get($tier-colors, corporate); } // corporate
    &.tier-Q { background: map-get($tier-colors, cqcd); } // CQCD
    &.tier-A { background: map-get($tier-colors, panier); } // Panierdachat
    &.tier-P { background: map-get($tier-colors, bluecross); } // Pacific Blue Cross
    &.tier-F { background: map-get($tier-colors, affinity); } // affinity - Marketing campaigns
    &.tier-E { background: map-get($tier-colors, employee); } // employee
    .mat-mdc-chip-action-label {
      font-size: 0.75rem;
      color: #FFFFFF;
    }
  }
  &.mat-mdc-standard-chip.chip-invoice,
  &.chip-invoice {
    height: 18px;
    &.status-open { background: map-get($invoice-status-colors, open); }
    &.status-warn { background: map-get($invoice-status-colors, warning); }
    &.status-paid { background: map-get($invoice-status-colors, paid); }
    &.status-overdue { background: map-get($invoice-status-colors, overdue); }
    &.status-processing { background: map-get($invoice-status-colors, processing); }
    .mat-mdc-chip-action-label {
      font-size: 0.75rem;
      color: #FFFFFF;
    }
  }
}

h3 {
  .mat-mdc-chip {
    top: -2px;
  }
}

.pill-box {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.75rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &.pill-status {
    display: inline-flex;
    background: #FF9500;
    padding: .25rem .75rem .2rem .75rem;
    color: #FFF;
    border-width: 0;
    cursor: default;
    &.unconfirmed,
    &.cancelled,
    &.claim-rejected,
    &.cancelRejected {
      background: #D9534F;
    }
    &.confirmed,
    &.byoa,
    &.created {
      background: #009BFF;
    }
    &.ma {
      background: #1AC7AD
    }
    &.in-transit {
      background: #397799;
    }
    &.claim-approved,
    &.delivered,
    &.partially-delivered {
      background: #00BFA5;
    }
    &.attention {
      background: #E87461;
    }

    &.batch-created {
      background: #1AC7AD;
    }
    &.batch-in-progress {
      background: #009BFF;
    }
  }
}
