h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $font-family--titles;
}

h2,
.h2 {
  font-size: 1.75rem;
  font-weight: bold;
}
