// Text Size Size
@each $key,$value in map-get($text-sizes,'mobile') {
  .text--#{$key} {
    @include u-set-text-size('mobile',$key);

  }
}

@media #{$medium-up} {
  @each $key,$value in map-get($text-sizes,'desktop') {
    .text--#{$key} {
      @include u-set-text-size('desktop',$key);
    }
  }
}

