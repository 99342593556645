.d-md-up,
.d-md-only,
.d-md-down,
.d-lg-up,
.d-lg-only,
.d-xlg-up,
.d-xlg-only
.d-xxlg-up,
.d-xxlg-only
{
  display: none;
}


@media #{$medium-down} {
  .d-md-down {
    display: block;
  }
}
@media #{$medium-up} {
  .d-sm-only {
    display: none;
  }

  .d-md-up {
    display: block;
  }
}

@media #{$medium-only} {
  .d-md-only {
    display: block;
  }
}

@media #{$large-up} {
  .d-lg-up {
    display: block;
  }
}

@media #{$large-only} {
  .d-lg-only {
    display: block;
  }
}

@media #{$xlarge-up} {
  .d-xlg-up {
    display: block;
  }
}

@media #{$xxlarge-up} {
  .d-xxlarge-up {
    display: block;
  }
}

.display--none {
  display: none !important;
}
