label {
  font-weight: map-get($font-weights, medium);
  display: flex;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined {
  .mat-mdc-form-field-flex {
    .mat-mdc-floating-label {
      top: 20px;
      &.mdc-floating-label--float-above {
        --mat-mdc-form-field-label-transform: translateY( -26.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
        transform: var(--mat-mdc-form-field-label-transform);
      }
    }
  }
  .mat-mdc-form-field-infix {
    padding-top: 12px;
    padding-bottom: 11px;
    min-height: 44px;
  }
  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 48px;
    height: 42px;
    padding: 9px;
  }
  .mat-mdc-form-field-icon-prefix>.mat-icon,
  .mat-mdc-form-field-icon-suffix>.mat-icon {
    padding: 8px;
  }
}

// Quill Editor
.ql-toolbar.ql-snow {
  border-bottom: none;
  border-color: rgba(0, 0, 0, 0.38);
  border-radius: 4px 4px 0 0;
}
.ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.38);
  border-radius:  0 0 4px 4px;
  min-height: 75px;
}

.mat-mdc-radio-group {
  display: flex;
  flex-wrap: wrap;
  .mat-mdc-radio-button {
    display: flex;
    width: 100%
  }
}
