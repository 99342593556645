.container {
  @include u-grid-container($grid-width);
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.container .container {
  padding-left:0;
  padding-right:0;
}

.container--full-width {
  &.main-content {
    padding-left: calc(($column-gutter * 1.5) * 1px);
    padding-right: calc(($column-gutter * 1.5) * 1px);
    padding-bottom: calc(($column-gutter * 1.5) * 1px);
    min-height: 100vh;
  }
}

.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.no-gutter {
  padding-left: 0;
  padding-right: 0;
}
.max--height {
  min-height: 100vh;
  overflow-x: hidden;
}
.mx--auto {
  margin-left: auto;
  margin-right: auto;
}
.row-no-collapse {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .column {
    padding-left: calc($column-gutter * 1px);
    padding-right: calc($column-gutter * 1px);
  }
}
.row--gutter {
  padding-left: calc(($column-gutter / 2) * 1px);
  padding-right: calc(($column-gutter / 2) * 1px);
}
.justify-content-left {
  justify-content: left!important;
}
.justify-content-right {
  justify-content: right!important;
}
.justify-content-center {
  justify-content: center!important;
}
.justify-items-center {
  justify-items: center!important;
}

@include u-create-grid-row(no-collapse);

.column {
  padding-bottom: calc(($column-gutter / 2) * 1px);
  &:last-child {
    padding-bottom: 0;
  }
}
.column .row .column:first-child {
  padding-left:0;
}
.column .row .column:last-child {
  padding-right:0;
}

.column .row--flipped .column:first-child {
  padding-right:0;
  padding-left: calc(($column-gutter / 2) * 1px);
}
.column .row--flipped .column:last-child {
  padding-left:0;
  padding-right: calc(($column-gutter / 2) * 1px);
}

.column--auto {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.column--no-flexgrow {
  flex-grow: 0;
}
.column--no-padding {
  padding-left: 0;
  padding-right: 0;
}
.column--border-left {
  position: relative;
  &::before {
    content: ' ';
    position: absolute;
    height: 100%;
    width: 1px;
    background: #B7B7B7;
    left: calc(($column-gutter / 2) * -1px)
  }
}

.row {
  width: 100%;
  display: inline-flex;
  justify-content: left;
  align-items: stretch;
  flex-wrap: wrap;
  gap: calc($column-gutter * 1px);
  &.row-start {
    justify-content: start;
  }
}

.row {
  &--reversed {
    flex-direction: row-reverse;
  }
}
@include u-create-grid-row();
@include u-create-grid-columns();

@media #{$small-up} {
  @include u-create-grid-row(sm);
  @include u-create-grid-columns(sm);

  .column-sm--border-left {
    position: relative;
    &::before {
      content: ' ';
      position: absolute;
      height: 100%;
      width: 1px;
      background: #B7B7B7;
      left: calc(($column-gutter / 2) * -1px)
    }
  }
}
@media #{$medium-up} {
  @include u-create-grid-row(md);
  @include u-create-grid-columns(md);

  .column-md--border-left {
    position: relative;
    &::before {
      content: ' ';
      position: absolute;
      height: 100%;
      width: 1px;
      background: #B7B7B7;
      left: calc(($column-gutter / 2) * -1px)
    }
  }
}
@media #{$large-up} {
  @include u-create-grid-row(lg);
  @include u-create-grid-columns(lg);
}
@media #{$xlarge-up} {
  @include u-create-grid-row(xl);
  @include u-create-grid-columns(xl);
}
@media #{$xxlarge-up} {
  @include u-create-grid-row(xxl);
  @include u-create-grid-columns(xxl);
}
