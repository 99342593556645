.company-charge-cost-cell {
  vertical-align: top;
  padding: 16px !important; // override mat-cel values
}

.no-gap {
  gap: 0;
}


