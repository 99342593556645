// Login component
.firebaseui-container {
  max-width: auto;
}
.mdl-button--raised.mdl-button--colored {
  background: map-get($colors, btn-green) !important;
  border-radius: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-transform: none;
  &:active,
  &:focus,
  &:hover {
    background: map-get($colors, btn-green-hover) !important;
    box-shadow: var(--mdc-protected-button-hover-container-elevation, 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12));
  }
}
.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
  background-color: map-get($colors, btn-green);
  text-transform: none;
}
.mdl-button--primary.mdl-button--primary,
.firebaseui-link {
  color: map-get($colors, btn-green);
  text-transform: none;
}
.mdl-shadow--2dp {
  box-shadow: none;
}
