.mdc-button:not(.btn-utility){
  &.mat-mdc-unelevated-button,
  &.mat-mdc-raised-button,
  &.mat-mdc-outlined-button {
    border-radius: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: inherit;
    transition: all ease-in-out 250ms;
  }

  &.mdc-small-icon {
    .mat-icon {
      font-size: 1em;
      height: 1em;
      width: 1em;
      margin-bottom: -4px;
    }
  }

  &.mdc-small-button {
    min-width: auto;
  }
}

.mdc-icon-button {
  &.mat-small-icon {
    min-width: auto;
    width: 34px;
    height: 34px;
    padding: 3px;
    .mat-icon {
      &::before {
        font-size: 18px;
      }
    }
  }

  &.mat-smaller-icon {
    min-width: auto;
    width: 30px;
    height: 30px;
    padding: 3px;
    .mat-icon {
      &::before {
        position: relative;
        top: -4px;
        font-size: 14px;
      }
    }
  }
}
