.mdc-snackbar {
  &.success {
    .mdc-snackbar__surface {
      background: map-get($colors, primary-green-hover);
      border: map-get($colors, primary-green-dark);
    }
  }
  &.error {
    .mdc-snackbar__surface {
      background: map-get($colors, danger-hover);
      border: map-get($colors, danger-dark);
    }
  }
  &.error,
  &.success {
    .mat-mdc-snack-bar-label {
      text-align: center;
      color: #ffffff;
    }
  }
}
