.mat-mdc-dialog-container {
  .mdc-dialog__container .mdc-dialog__surface {
    border-radius: 8px;
  }
  .mat-mdc-dialog-title.mdc-dialog__title {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: map-get($font-weights, bold);
    height: auto;
    padding-top: 1rem;
    &::before {
      display: none;
    }
  }
  .mat-mdc-dialog-actions {
    padding: 9px 24px 24px 24px;
  }
  .mat-dialog-body {
    font-size: 1rem;
    line-height: 1.25rem;
    color: map-get($colors, body-text);
    padding: 0.575rem 0;
  }
}
