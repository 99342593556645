.mat-mdc-menu-item {
  &.active {
    background: map-get($colors, primary-green) !important;
    color: #FFFFFF;
    .mdc-list-item__primary-text,
    .mat-icon-no-color,
    .mat-mdc-menu-submenu-icon {
      color: #FFFFFF;
    }
  }
  &:hover:not(.active) {
    background: map-get($colors, menu-green-hover) !important;
  }
}
