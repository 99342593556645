// A map containing the basic typography for all the site
// The 2nd level of the map (body and heading) corresponds to the main typefaces.
// The 3rd level of the map corresponds to font property combinations that frequently go together.
// Any arbitrary word goes as a group name. The properties are usually font-size and line-height but
// can be also any other. Please avoid including color and other non typography properties here, though.
// Usage: @include u-set-typography($typeface, $style-name);

$font-family--base: 'Inter', Helvetica, Arial, sans-serif;
$font-family--titles: 'Poppins', Helvetica, Arial, sans-serif;
$font-scale-ratio: 1.125;
$font-scale-ratio--small: 1.1;
$font-base-size: 1rem;
$base-font: 16px;
$base-line-height--small: 1.4;
$base-line-height: 1.6666666667;

$text-sizes: (
  'mobile': (
    'xs': 12,
    'sm': 14,
    'base': 16,
    'md': 24,
    'lg': 30,
    'xl': 30,
    'xxl': 36,
    'xxxl': 36,
    'xxxxl': 36,
  ),
  'tablet': (
    'xs': 12,
    'sm': 14,
    'base': 16,
    'md': 24,
    'lg': 30,
    'xl': 36,
    'xxl': 48,
    'xxxl': 48,
    'xxxxl': 48,
  ),
  'desktop': (
    'xs': 12,
    'sm': 14,
    'base': 16,
    'md': 24,
    'lg': 30,
    'xl': 36,
    'xxl': 48,
    'xxxl': 64,
    'xxxxl': 84,
  ),
);

$font-weights: (
  thin:        100,
  extra-light: 200,
  light:       300,
  normal:      400,
  medium:      500,
  semi-bold:   600,
  bold:        700,
  extra-bold:  800,
  black:       900,
);
