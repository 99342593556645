// COLORS
@each $color, $hex in $colors {
  .text--#{""+$color} {
    color: $hex !important;
  }
  .text-#{""+$color} {
    color: $hex !important;
  }

  .bg--#{""+$color} {
    background: $hex !important;
  }
  .bg-#{""+$color} {
    background: $hex !important;
  }

  .bg-gradient--#{""+$color} {
    background: transparent linear-gradient(90deg, $hex 40%, rgba(255, 255, 255, 0) 75%) 0% 0% no-repeat padding-box !important;
  }
}
@each $color, $hex in $tier-colors {
  .text-tier-#{""+$color} {
    color: $hex !important;
  }

  .bg-tier-#{""+$color} {
    background: $hex !important;
  }

  .bg-gradient-tier-#{""+$color} {
    background: transparent linear-gradient(90deg, $hex 40%, rgba(255, 255, 255, 0) 75%) 0% 0% no-repeat padding-box !important;
  }
}

// GRADIENTS
@each $name, $hex in $gradients {
  .gradient--#{$name} {
    background: #{$hex} no-repeat 0 0;
  }
}

// TEXT SIZES
@each $key, $value in map-get($text-sizes, 'mobile') {
  .text--#{$key} {
    @include u-set-text-size('mobile', $key);
  }
}

@media #{$medium-up} {
  @each $key, $value in map-get($text-sizes, 'tablet') {
    .text--#{$key} {
      @include u-set-text-size('tablet', $key);
    }
  }
}
@media #{$large-up} {
  @each $key, $value in map-get($text-sizes, 'desktop') {
    .text--#{$key} {
      @include u-set-text-size('desktop', $key);
    }
  }
}

// TEXT HELPERS
.no-underline {
  text-decoration: none;
}
.align-center {
  text-align: center!important;
}
.align-left {
  text-align: left!important;
}
.align-right {
  text-align: right!important;
}
.bold {
  font-weight: bold;
}
.text-no-transform {
  text-transform: none;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-decoration-none {
  text-decoration: none;
}
.text-larger {
  font-size: 1.125rem;
}
.lineheight-larger {
  line-height: 1.125rem;
}
.lineheight--condensed {
  line-height: 1.15;
}
.lineheight--loose {
  line-height:1.5;
}
.lineheight--tight {
  line-height:1.3333333333;
}
.lineheight--none {
  line-height: 1;
}
.nobr {
  white-space: nowrap;
}
.full-width {
  width: 100%;
}
.no-bold {
  font-weight: map-get($font-weights, normal)!important;
}
.semi-bold {
  font-weight: map-get($font-weights, medium)!important;
}
.bold {
  font-weight: map-get($font-weights, bold)!important;
}
.optional-text {
  font-size: 0.75rem;
  color: map-get($colors, text-gray-optional);
}
.no-list-style {
  list-style: none;
  padding-left: 0;
}
.cursor-pointer {
  cursor: pointer;
}

// IMAGES
.img--full-width {
  display: block;
  max-width: 100%;
}
.img--center {
  margin-left: auto;
  margin-right: auto;
}

// POSITIONING
.position {
  &-relative {
    position: relative;
  }
  &-absolute {
    position: absolute;
  }
  &-fixed {
    position: fixed;
  }
}

// DISPLAY
.display {
  &-flex {
    display: flex;
  }
  &-inline-flex {
    display: inline-flex;
  }
  &-inline {
    display: inline;
  }
  &-inline-block {
    display: inline-block;
  }
  &-block {
    display: block;
  }
}

// CIRCLES
.circle {
  width: 14px;
  height: 14px;
  opacity: 1;
  border-radius: 50%;
  display: inline-block!important;
  position: relative;
  margin-left: -5px;
  &:first-child {
    margin-left: 0;
  }

  &.Nationex {
    background: #00703C!important;
  }
  &.Rivo {
    background: #F50AA0!important;
  }
  &.AirCanadaDomestic {
    background: #F01428!important;
  }
  &.AirCanadaInternational {
    background: #AD1220!important;
  }
  &.UniUni {
    background: #F68D2E!important;
  }
  &.Spring {
    background: #E84C05!important;
  }
  &.Intelcom {
    background: #FF9D00!important;
  }
  &.Ulala {
    background: #026DFF!important;
  }
}

// BORDER
.border {
  &-left {
    border-left: 1px solid map-get($colors, borders) !important;
  }
  &-right {
    border-right: 1px solid map-get($colors, borders) !important;
  }
  &-bottom {
    border-bottom: 1px solid map-get($colors, borders) !important;
  }
  &-top {
    border-top: 1px solid map-get($colors, borders) !important;
  }
  &.bottom {
    position: relative;
    &::after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: calc($column-gutter * -1px);
      left: 0;
      padding-bottom: calc($column-gutter * 1px);
      border-bottom: 1px solid map-get($colors, borders);
    }
  }
  &.top {
    position: relative;
    &::before {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      top: calc($column-gutter * -1px);
      left: 0;
      padding-top: calc($column-gutter * 1px);
      border-top: 1px solid map-get($colors, borders);
    }
  }
  &.right {
    position: relative;
    &::after {
      content: '';
      height: 100%;
      width: 1px;
      position: absolute;
      top: 0;
      right: calc($column-gutter * -1px);
      padding-right: calc($column-gutter * 1px);
      border-right: 1px solid map-get($colors, borders);
    }
  }
  &.left {
    position: relative;
    &::before {
      content: '';
      height: 100%;
      width: 1px;
      position: absolute;
      top: 0;
      left: calc($column-gutter * -1px);
      padding-left: calc($column-gutter * 1px);
      border-left: 1px solid map-get($colors, borders);
    }
  }
  &.no-card-space {
    margin: 0px -16px;
    padding-left: 32px;
  }
}

// Pricing Tiers
.pricing-tier-text {
  &.B { color: map-get($tier-colors, bronze); } // bronze
  &.S { color: map-get($tier-colors, silver); } // silver
  &.G { color: map-get($tier-colors, gold); } // gold
  &.C { color: map-get($tier-colors, corporate); } // corporate - custom
  &.Q { color: map-get($tier-colors, cqcd); } // CQCD
  &.A { color: map-get($tier-colors, panier); } // Panierdachat
  &.P { color: map-get($tier-colors, bluecross); } // Pacific Blue Cross
  &.F { color: map-get($tier-colors, affinity); } // affinity - Marketing campaigns
  &.E { color: map-get($tier-colors, employee); } // employee
}
